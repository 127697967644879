// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--f287e";
export var animateScrollOut = "styles-module--animateScrollOut--874c6";
export var animationContainer = "styles-module--animationContainer--6ef0b";
export var animationContainerBottom = "styles-module--animationContainerBottom--76ca5";
export var animationContainerTop = "styles-module--animationContainerTop--8422e";
export var background = "styles-module--background--c01d6";
export var colorSequence = "styles-module--colorSequence--f50ed";
export var connectBubble = "styles-module--connectBubble--18a4e";
export var container = "styles-module--container--55608";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--9873a";
export var grow = "styles-module--grow--c5ef6";
export var growAndShrink = "styles-module--growAndShrink--24dd1";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--2737c";
export var link = "styles-module--link--4d485";
export var moveBg = "styles-module--move-bg--69393";
export var rotateLoop = "styles-module--rotateLoop--c17e6";
export var spin = "styles-module--spin--3b6bd";
export var spinCounter = "styles-module--spinCounter--b5045";
export var textContainer = "styles-module--textContainer--b81e8";